@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}
.body-kd {
  font-family: 'Trebuchet MS', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  min-height: 100vh;
  color: #fff;
  background-color: var(--tundora);
}

.header-background {
  background: var(--bittersweet);
  @media only screen and (min-width: 400px) {
    background: var(--bittersweet) url('/header-background-kd.svg') no-repeat center bottom;
  }
}